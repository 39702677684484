import {
  find, reduce, map, orderBy,
} from 'lodash';
import { getMarketTemplateDisplayName, getMarketTemplateDisplayPosition } from '@/services/helpers/market-template-mapper';

const transformMarketConfigurations = (marketConfigurations, displayMarketsConfiguration) => reduce(
  marketConfigurations,
  (templates, marketConfiguration) => [
    ...templates,
    {
      marketTemplateId: marketConfiguration.sportLogicTemplate.marketTemplate.marketTemplateId,
      code: marketConfiguration.sportLogicTemplate.marketTemplate.template.code,
      marketCode: marketConfiguration.sportLogicTemplate.marketTemplate.template.marketCode,
      name: getMarketTemplateDisplayName(marketConfiguration.sportLogicTemplate.marketTemplate, displayMarketsConfiguration),
      displayConfiguration: marketConfiguration.displayConfiguration,
      enabled: marketConfiguration.enabled,
      offeringConfiguration: marketConfiguration.offeringConfiguration,
      resultingConfiguration: marketConfiguration.resultingConfiguration,
      cashoutConfiguration: marketConfiguration.cashoutConfiguration,
      sportLogicTemplate: marketConfiguration.sportLogicTemplate,
      sportsLogicTemplateId: marketConfiguration.sportsLogicTemplateId,
      templateConfiguration: marketConfiguration.templateConfiguration,
      limitConfiguration: marketConfiguration.limitConfiguration || marketConfiguration.sportLogicTemplate?.defaultLimitConfiguration,
      marginConfiguration: marketConfiguration.marginConfiguration || marketConfiguration.sportLogicTemplate?.defaultMarginConfiguration,
      template: marketConfiguration.sportLogicTemplate.marketTemplate.template,
      position: getMarketTemplateDisplayPosition(marketConfiguration.sportLogicTemplate.marketTemplate, displayMarketsConfiguration),
    },
  ],
  [],
);

const transformMarketTemplates = (marketTemplates, displayConfigurations) => reduce(
  marketTemplates,
  (templates, marketTemplate) => [
    ...templates,
    ...map(
      marketTemplate.sportLogicTemplates?.nodes || [],
      (sportLogicTemplate) => ({
        marketTemplateId: marketTemplate.template.marketTemplateId,
        code: marketTemplate.template.code,
        marketCode: marketTemplate.template.marketCode,
        name: getMarketTemplateDisplayName(marketTemplate, displayConfigurations),
        displayConfiguration: sportLogicTemplate.defaultDisplayConfiguration,
        enabled: true,
        offeringConfiguration: sportLogicTemplate.defaultOfferingConfiguration,
        resultingConfiguration: sportLogicTemplate.defaultResultingConfiguration,
        cashoutConfiguration: sportLogicTemplate.defaultCashoutConfiguration,
        sportLogicTemplate,
        sportsLogicTemplateId: sportLogicTemplate.sportLogicTemplateId,
        templateConfiguration: sportLogicTemplate.defaultTemplateConfiguration,
        limitConfiguration: sportLogicTemplate.defaultLimitConfiguration,
        marginConfiguration: sportLogicTemplate.defaultMarginConfiguration,
        template: marketTemplate.template,
        position: getMarketTemplateDisplayPosition(marketTemplate, displayConfigurations),
      }),
    ),
  ],
  [],
);

const filterInactiveMarketTemplates = (targetMarketTemplates, activeMarketTemplates) => reduce(
  targetMarketTemplates,
  (inactiveMarketTemplates, marketTemplate) => {
    const isActiveMarket = !!find(activeMarketTemplates, { marketTemplateId: marketTemplate.marketTemplateId });
    return isActiveMarket ? inactiveMarketTemplates : [
      ...inactiveMarketTemplates,
      marketTemplate,
    ];
  },
  [],
);

const orderMarketTemplates = (marketTemplates) => orderBy(marketTemplates, ['template.staticParams.PERIOD', 'template.staticParams.HALF', 'template.staticParams.TEAM',
  'template.staticParams.RESULT_AFTER_X', 'template.staticParams.RACE_TO', 'template.staticParams.SCORE_INDEX',
  'template.staticParams.DRIVE_INDEX', 'template.staticParams.BIN_MINUTE_START', 'template.staticParams.PUNT', 'template.staticParams.PLAY',
  'template.staticParams.FIELD_GOAL', 'template.staticParams.POSSESSION', 'template.staticParams.YARD_LINE', 'template.staticParams.PITCH_INDEX',
  'template.staticParams.PLATE_APPEARANCE', 'template.staticParams.IS_TOP_INNING', 'template.staticParams.TEAM_SCORE_AT_CREATION',
  'template.staticParams.HOME_SCORE_AT_CREATION', 'template.staticParams.AWAY_SCORE_AT_CREATION', 'template.staticParams.MILESTONE_YARDS']);

const createSportTemplateRequestPayload = (sportTemplate) => ({
  name: sportTemplate.name,
  sportId: sportTemplate.sport.sportId,
  sportTemplateId: sportTemplate.sportTemplateId,
  isDefault: sportTemplate.default,
  limitConfiguration: sportTemplate.limitconfiguration,
  marketConfigurations: reduce(
    sportTemplate.marketConfigurations,
    (serializedMarketConfigurations, rawMarketConfiguration) => {
      const {
        code,
        name,
        sportsLogicTemplateId,
        template,
        ...marketConfiguration
      } = rawMarketConfiguration;

      if (marketConfiguration?.sportLogicTemplate) {
        return {
          ...serializedMarketConfigurations,
          [sportsLogicTemplateId]: {
            ...marketConfiguration,
            sportLogicTemplate: {
              ...marketConfiguration?.sportLogicTemplate.template,
            },
          },
        };
      }

      return {
        ...serializedMarketConfigurations,
        [sportsLogicTemplateId]: marketConfiguration,
      };
    },
    {},
  ),
});

export default {
  transformMarketConfigurations,
  transformMarketTemplates,
  filterInactiveMarketTemplates,
  orderMarketTemplates,
  createSportTemplateRequestPayload,
};
