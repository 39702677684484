export const periodLabels = {
  PRE_GAME: 'Pregame',
  PREMATCH: 'Pregame',
  TOTAL: 'Match',
  FIRST_PERIOD: '1st quarter',
  BEFORE_SECOND_PERIOD: 'Before 2nd quarter',
  SECOND_PERIOD: '2nd quarter',
  FIRST_HALF: '1st half',
  IN_FIRST_HALF: '1st half',
  HALF_TIME: 'Half time',
  SECOND_HALF: '2nd half',
  IN_SECOND_HALF: '2nd half',
  THIRD_PERIOD: '3rd quarter',
  BEFORE_FOURTH_PERIOD: 'Before 4th quarter',
  FOURTH_PERIOD: '4th quarter',
  BEFORE_OVERTIME: 'Before overtime',
  IN_OVERTIME: 'Overtime',
  OVERTIME_1: 'Overtime',
  OVERTIME_2: '2nd overtime',
  OVERTIME_3: '3rd overtime',
  OVERTIME_4: '4th overtime',
  OVERTIME_5: '5th overtime',
  OVERTIME_6: '6th overtime',
  OVERTIME_7: '7th overtime',
  OVERTIME_8: '8th overtime',
  OVERTIME_9: '9th overtime',
  OVERTIME_10: '10th overtime',
  POST_GAME: 'End',
  GAME_ABANDONED: 'Suspended',
  AT_HALF_TIME: 'Half time',
  IN_FIRST_PERIOD: '1st quarter',
  IN_FIRST_QUARTER: '1st quarter',
  IN_SECOND_PERIOD: '2nd quarter',
  IN_SECOND_QUARTER: '2nd quarter',
  IN_THIRD_PERIOD: '3rd quarter',
  IN_THIRD_QUARTER: '3rd quarter',
  IN_FOURTH_PERIOD: '4th quarter',
  IN_FOURTH_QUARTER: '4th quarter',
  NORMAL_TIME_COMPLETED: 'Normal time completed',
  OVER_TIME_COMPLETED: 'Overtime completed',
};

export const periodPositions = {
  PRE_GAME: 1,
  PREMATCH: 1,
  TOTAL: 2,
  FIRST_PERIOD: 3,
  IN_FIRST_PERIOD: 3,
  IN_FIRST_QUARTER: 3,
  BEFORE_SECOND_PERIOD: 4,
  SECOND_PERIOD: 5,
  IN_SECOND_PERIOD: 5,
  IN_SECOND_QUARTER: 5,
  IN_FIRST_HALF: 6,
  FIRST_HALF: 6,
  HALF_TIME: 7,
  AT_HALF_TIME: 7,
  IN_SECOND_HALF: 8,
  SECOND_HALF: 8,
  THIRD_PERIOD: 9,
  IN_THIRD_PERIOD: 9,
  IN_THIRD_QUARTER: 9,
  BEFORE_FOURTH_PERIOD: 10,
  FOURTH_PERIOD: 11,
  IN_FOURTH_PERIOD: 11,
  IN_FOURTH_QUARTER: 11,
  NORMAL_TIME_COMPLETED: 12,
  BEFORE_OVERTIME: 13,
  OVERTIME: 14,
  IN_OVERTIME: 14,
  OVERTIME_1: 15,
  OVERTIME_2: 16,
  OVERTIME_3: 17,
  OVERTIME_4: 18,
  OVERTIME_5: 19,
  OVERTIME_6: 20,
  OVERTIME_7: 21,
  OVERTIME_8: 22,
  OVERTIME_9: 23,
  OVERTIME_10: 24,
  OVER_TIME_COMPLETED: 25,
  GAME_ABANDONED: 26,
  POST_GAME: 27,
};
